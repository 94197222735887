import 'es7-object-polyfill';
import 'array.prototype.find';
import 'polyfill-object.fromentries';
import 'polyfill-array-includes';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App/App';
import featureConfiguration from "./featureConfiguration/featureConfiguration";
import * as serviceWorker from './serviceWorker';
import BrowserSupport from "./BrowserSupport/BrowserSupport";
import InsightsClient from './App/pages/SearchPage/InsightsClient';
import DoNotTrack from './App/_shared/helpers/DoNotTrack';

require('array.prototype.find').shim();

sessionStorage.setItem("webp", "");
BrowserSupport.init();
const container = document.getElementById('AppRoot');
 // eslint-disable-next-line no-undef
 const root = createRoot(container);
 root.render(<App />);
featureConfiguration();
InsightsClient();
DoNotTrack();
serviceWorker.unregister();
