
import styling from "./DynamicForm.module.scss"
import React, { useState, useEffect, useRef } from 'react';
import Alert from '../../Synapse/components/organisms/Alert';
import envDataConfig from '../../../EnvDataConfig/EnvDataConfig';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import CryptoJS from 'crypto-js';
import IComponentProps from '../../../interfaces/IComponentProps';
import FormField, { IFormField } from "./FormField";
import ContentfulReactRenderOptions from "../../_shared/ContentfulReactRenderOptions";
import {documentToReactComponents} from "@contentful/rich-text-react-renderer";
import FormModel from "../../../models/FormModel";
import ReCAPTCHA from "react-google-recaptcha";
import processBiography from "../../_shared/helpers/processBiography";

function Encrypt(value: any) {
  var key = CryptoJS.enc.Utf8.parse(envDataConfig.SendGridClientKey);
  var iv = CryptoJS.enc.Utf8.parse("7061737323313233");
  var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value), key,
      {
          keySize: 128 / 8,
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7
      });
  return encrypted.toString();
}

export default function DynamicForm({id, htmlId, containerName, captcha, containerDescription, groupForms, formAction, formMessages}: (FormModel & IComponentProps)) {

  const [messageSuccess, setMessageSuccess] = useState(false);
  const [messageError, setMessageError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("There was an error sending your message.  Please try again.")
  const [isValid, setIsValid] = useState(false);
  const [open, setOpen] = useState(false);
  const [shouldResetForm, setShouldResetForm] = useState(false);
  const [formData, setFormData] = useState({});
  const hasData = !!groupForms?.length;

  const formFields = hasData ? groupForms[0].formFields : [];

  const validate = (formData: any, formFields: IFormField[], setIsValid: Function) => {
    formFields.every(field => {
      if(field.fieldRequired) {
        //@ts-ignore
        if(formData[field.fieldName] !== undefined) {
          //@ts-ignore
          if (formData[field.fieldName] === null || formData[field.fieldName] === '') {
            setIsValid(false);
            return false;
          } 
        } else {
          setIsValid(false);
          return false;
        }        
      }
      setIsValid(true);
      return true;
    });
  }

  const handleChange = (value: string | boolean, fieldName: string) => {
    setFormData({
      ...formData,
      [fieldName]: value
    });
  }

  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if(reason === 'clickaway') {
      return
    }
    setOpen(false);
  }

  useEffect(() => {
    validate(formData, formFields, setIsValid);
  },[formData, formFields, setIsValid]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    // if there's captcha check for the token
    let hasToken = !captcha;
    if(captcha){
      hasToken = !!await recaptchaRef.current?.executeAsync();
    }
    if (hasToken) {
      let formattedFormData = {};
      formFields.forEach((field) => {
        // @ts-ignore
        formattedFormData[field.labelName] = Encrypt(formData[field.fieldName])
        //formattedFormData[field.fieldName] = formData[field.fieldName]
      })  
      // @ts-ignore
      formattedFormData['Form Email'] = Encrypt(formAction?.email)
      // @ts-ignore
      formattedFormData['Dynamic Form Name'] = Encrypt(containerName)
      
      if(formAction?.dataSubmission === 'Email') {
        sendEmail(formattedFormData, e);
      }

      if(formAction?.dataSubmission === 'Database') {
        if(formAction?.database === 'SQL') {
          // Future Enhancement
          // sendToDatabase(e);
        }

        if(formAction?.database === 'CRM') {
          // Future Enhancement
          // sendToCRM();
        }
      }
    }
  }

  async function sendEmail(formattedFormData: any, e: any){
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formattedFormData)
    };

    fetch(envDataConfig.baseUrl + 'Email/DynamicForm', requestOptions)
    .then(res => {
      if(res.status === 404) {
        setErrorMessage("404 - Server Not Found");
        setMessageError(true);
      } else {
        setFormData({});
        setShouldResetForm(true);
        setMessageSuccess(true);
        setIsValid(false);
        recaptchaRef.current?.reset();
        setTimeout(function() {
          setMessageSuccess(false);
        }, 60000);
      }
    }).then(()=>{
      setTimeout(function() {
        setShouldResetForm(false);
      }, 500);
    })
    .catch(error => {
      setIsValid(false);
      setErrorMessage(error.statusText);
      setMessageError(true);
      setTimeout(function() {
        setMessageError(false);
      }, 5000);
    });
  }

  return hasData ? (
   <form id={htmlId} data-contentid={id} className={styling["dynamic-form"]} noValidate onSubmit={handleSubmit}>
      <Card className={`${styling["contact-form-card"]} ${styling["mat-elevation-z14"]}`}>

        {!!formMessages?.warningMessage?.message &&
          <div className="mb-2">
            <Alert className="warning" type="warning">
              {documentToReactComponents(processBiography(formMessages.warningMessage.message), ContentfulReactRenderOptions)}
            </Alert>
          </div>
        }
        {!!formMessages?.infoMessage?.message &&
          <Alert className="info" type="info">
          {documentToReactComponents(processBiography(formMessages.infoMessage.message), ContentfulReactRenderOptions)}
          </Alert>
        }

        <h2>{containerName}</h2>
        <div className={`${styling["rich-text"]}`}>
          {containerDescription && documentToReactComponents(containerDescription, ContentfulReactRenderOptions)}
        </div>
        {
          formFields?.map((formField: any, key: number)=>{
            return (
                <div key={key}>
                    <FormField 
                      {...formField}
                      // @ts-ignore
                      formValue={formData[formField.fieldName]}
                      handleChange={(e: any)=>handleChange(e, formField.fieldName)}
                      shouldReset={shouldResetForm}
                    />    
                  <br/>
                </div>   
            )
          })
        }

        {captcha && <ReCAPTCHA
          ref={recaptchaRef}
          size="invisible"
          sitekey={envDataConfig.ReCaptchaAPIKey}
          badge="bottomleft"
        />
        }

        {
          <Button 
            type="submit"
            disabled={!isValid}
            variant="contained"
            className={`${!isValid ? "disabled" : ""} ${styling["mat-elevation-z7"]} ${styling["form-button"]}`}
          >
            Submit
          </Button>
        }

        {messageSuccess &&
          <div className="success">
            <br/>
            <Alert type="success">
              {documentToReactComponents(processBiography(formMessages?.successMessage.message), ContentfulReactRenderOptions)}
            </Alert>
          </div>
        }

        {messageError && 
          <div className="error">
            <br/>
            <Alert type="error">
              {formMessages?.errorMessage && documentToReactComponents(processBiography(formMessages?.errorMessage.message), ContentfulReactRenderOptions)}
            </Alert>
          </div>
        }

        <Snackbar
          anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
          open={open}
          onClose={handleClose}
          message="Form was submitted successfully. A representative will contact you soon."
          autoHideDuration={4000} 
        />

      </Card>

    </form>
  ):<>
  {/* NO FORM DATA */}
  </>;
}