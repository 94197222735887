import {Document} from "@contentful/rich-text-types";
import CategoryCardListModel from "./CategoryCardListModel";
import DisclosureItemModel from "./DisclosureItemModel";
import ImageModel from "./ImageModel";
import SmartLinkModel from "./SmartLinkModel";
import SubEventModel from "./SubEventModel";
import FeatureModel from "./FeatureModel";
import LinkGroup from "./LinkGroup";
import AuthorGroupModel from "./AuthorGroupModel";

export enum BlogNewsEventType{
    Blog = "Blog",
    News = "News",
    Event = "Event",
    Videos = "Videos",
    Stories = "Stories"
}

export interface IAuthor {
    readonly name: string;
    readonly role: string;
    readonly biography?: Document | null | undefined;
    readonly image: ImageModel | undefined;
}
interface IPDFAttachment{
    readonly id: string;
    readonly title: string;
    readonly description: string;
    readonly pdfurl: string;
}
export default class BlogNewsEventsItemModel {
    public readonly id?: string | undefined;
    public readonly _clientType?: string = this.constructor.name;
    public readonly type?: BlogNewsEventType;
    public readonly author?: IAuthor;
    public readonly authors?: AuthorGroupModel | undefined;
    public readonly sidebar?: LinkGroup;
    public readonly publicationDate?: string;
    public readonly siteTag?: string;
    public readonly title?: string;
    public readonly slug?: string;
    public readonly eventName?: string;
    public readonly cardImage?: ImageModel | undefined;
    public readonly cardSummary?: string;
    public readonly content?: Document;
    public readonly feature?: FeatureModel;
    public readonly additionalContent?: Document;
    public readonly description?: Document;
    public readonly eventLocation?: string;
    public readonly subEvents?: SubEventModel[];
    public readonly startDate?: string;
    public readonly endDate?: string;
    public readonly pdfAttachment?: IPDFAttachment;
    public readonly registrationLink?: SmartLinkModel;
    public readonly directionsLink?: SmartLinkModel;
    public readonly relatedTools?: Readonly<CategoryCardListModel> | null;
    public readonly disclosures?: DisclosureItemModel[];

    constructor(props: Readonly<BlogNewsEventsItemModel> | undefined | null){

        const {id, type, author, authors, sidebar, publicationDate, siteTag, title, slug, cardImage, cardSummary, eventName, description, eventLocation, subEvents,
          content, registrationLink, directionsLink, pdfAttachment, relatedTools, disclosures, startDate, endDate, feature, additionalContent } = props || {};

        this.id = id;
        this.type = type;
        this.author = author;
        this.authors = authors
        this.sidebar = sidebar ? new LinkGroup(sidebar): undefined;
        this.publicationDate = publicationDate;
        this.siteTag = siteTag;
        this.title = title;
        this.slug = slug;
        this.eventName = eventName;
        this.eventLocation = eventLocation;
        this.startDate = startDate;
        this.endDate = endDate;
        this.cardImage = new ImageModel(cardImage);
        this.cardSummary = cardSummary;
        this.content = content;
        this.feature = feature ? new FeatureModel(feature) : undefined;
        this.additionalContent = additionalContent;
        this.description = description;
        this.pdfAttachment = pdfAttachment;
        this.registrationLink = registrationLink;
        this.directionsLink = directionsLink;
        this.relatedTools = new CategoryCardListModel(relatedTools);
        this.disclosures =  disclosures?.map(disclosure => new DisclosureItemModel(disclosure));
        this.subEvents = subEvents?.map(sb=>new SubEventModel(sb));
    }
}