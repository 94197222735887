import stylesBase from "../_HeroBase.module.scss";
import withErrorBoundary from "../../../_shared/helpers/withErrorBoundary";
import IComponentProps from "../../../../interfaces/IComponentProps";
import ThemeTypeEnum from "../../../../enums/ThemeTypeEnum";
import LinkButtonList from "../../_shared/LinkButtonList/LinkButtonList";
import PromotionModel from "../../../../models/PromotionModel";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import ContentfulReactRenderOptions from "../../../_shared/ContentfulReactRenderOptions";
import SideAlignmentEnum from "../../../../enums/SideAlignmentEnum";
import CalculateNavigationTheme from "../../../_shared/helpers/CalculateNavigationTheme";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateNavigationTheme } from "../../../../redux/slices/pageContentSlice";

export function PromoHeroRender(props: (PromotionModel & IComponentProps)) {
    const { id, htmlId, primary, className = "", theme, textAlignment, pz, content = {}} = props;
    const { header = "", body, links = [], image,
    mobileImage} = content;

    let layoutAlignmentClassName = "";
    let colAlignmentClassName = "";
    let centerClass = "";
    let centerContainerClass = "";
    if(textAlignment === SideAlignmentEnum.Right){
        layoutAlignmentClassName ="offset";
        colAlignmentClassName = "offset-md-4 offset-xl-6";
    };
    if(textAlignment === SideAlignmentEnum.Center){
        centerClass = "justify-content-center";
        centerContainerClass = "align-center";
    }

    const {
        imageURL: backgroundImageURL,
        description: backgroundDescription
    } = image || {};
    const {
        imageURL: mobileBackgroundImageURL,
        description: mobileBackgroundDescription
    } = mobileImage || {};

    const shouldShowBackground = !!image?.imageURL;
    const shouldShowMobileBackground = !!mobileImage?.imageURL;

    const backgroundStyle = shouldShowBackground ? {backgroundImage:`url(${backgroundImageURL})`} : {};
    const mobileBackgroundStyle = shouldShowMobileBackground ? {backgroundImage:`url(${mobileBackgroundImageURL})`} : {};

    let NavigationTheme = ThemeTypeEnum.Default;

    if(primary) {
        NavigationTheme = CalculateNavigationTheme(theme!);
    }

    const dispatch = useDispatch()

    useEffect(() => {
        if(primary) dispatch(updateNavigationTheme(NavigationTheme))
    },[ primary, dispatch, NavigationTheme ]);

    const themeClassName = (theme === ThemeTypeEnum.Dark) ? stylesBase.dark : stylesBase.light;
    return (
        <div id={htmlId} style={backgroundStyle} aria-label={backgroundDescription} className={`${layoutAlignmentClassName} ${stylesBase['c-hero']} ${centerContainerClass ? stylesBase[centerContainerClass]:""} ${themeClassName} ${className}`} data-contentid={id}>
            <div className="hero-mobile-image" aria-label={mobileBackgroundDescription} style={mobileBackgroundStyle}></div>
            <div className={`container`}>
                <div className={`row align-items-center ${centerClass}`}>
                    <div className={`col-12 col-md-8 col-xl-6 ${colAlignmentClassName}`}>
                        <div className={`hero-content`}>
                            <h1>{header}</h1>
                            {body && documentToReactComponents(body, ContentfulReactRenderOptions)}
                            <LinkButtonList links={links}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
PromoHeroRender.displayName = "PromoHero";
export default withErrorBoundary(PromoHeroRender);
