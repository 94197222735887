import SmartLinkModel from "../../models/SmartLinkModel";
import RichTextComponentTypesEnum from "../../enums/RichTextComponentTypesEnum";
import {ContentfulNode} from "../../models/ContentfulNode";
import SmartLink from "../components/_shared/SmartLink/SmartLink";
import VideoPlayerModel from "../../models/VideoPlayerModel";
import VideoPlayer from "../components/_shared/VideoPlayer/VideoPlayer";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

function renderRichText(node:ContentfulNode<SmartLinkModel> | any){

  const nodeType = node?.nodeType;
  
  let mainData = {
    ...node?.data?.target
  }
  if(mainData.videoLink){
    const videoModel = new VideoPlayerModel(mainData);
    return <VideoPlayer {...videoModel}/>
  }
  // embedded link fix
  if(mainData.link){
    const { link, type, linkText, name } = mainData;
    const { externalLink:showRedirectModal } = link;
    mainData = {
      ...link,
      type,
      linkText,
      name,
      showRedirectModal
    };
  }
  // sometimes we'll need to grab the text from the content node for the link
  if(node?.content?.length){
    const linkText = node.content[0].value;
    mainData = {
      ...mainData,
      linkText
    }
  }
  if(mainData.fields?.file){
    const url = `https://${node.data.target.fields.file.url}`;
    mainData = {
      ...mainData,
      url
    }
  }
  let linkData = new SmartLinkModel(mainData);
  return <SmartLink className={nodeType} {...linkData} />;
}
const renderAssetFromRichText = (node:any) => {
  return (
    <img
      src={`https://${node.data.target.fields.file.url}${sessionStorage.getItem("webp")}`}
      alt={node.data.target.fields.description}
    />
  );
}
interface IRichTable {
  content: any[]
}
function renderRichTable({content}: IRichTable){

  const rows = [...content];

  return (
    <div className="scrollable" style={{marginBottom: 40}}>
      <table>
        <thead>
          {documentToReactComponents(rows.shift())}
        </thead>
        <tbody>
          {rows.map(( row: any, index: number )=>{
            return (<tr key={index}>
              {row.content.map((cell:any, jindex: number)=>{
                const textHtml = cell.content?.length ? documentToReactComponents(cell.content[0]) : <></>;
                return <td key={jindex}>
                    {textHtml}
                </td>
              })}
            </tr>)
          })}
        </tbody>
      </table>
    </div>
  );
}

const ContentfulReactRenderOptions =  {
  renderNode: {
    [RichTextComponentTypesEnum.Table]: renderRichTable,
    [RichTextComponentTypesEnum.EntryHyperlink]: renderRichText,
    [RichTextComponentTypesEnum.AssetHyperlink]: renderRichText,
    [RichTextComponentTypesEnum.EmbeddedEntryInline]: renderRichText,
    [RichTextComponentTypesEnum.EmbeddedEntryBlock]: renderRichText,
    [RichTextComponentTypesEnum.EmbeddedAssetBlock]: renderAssetFromRichText,
  },
  renderText: (text:string) => text.split('\n').flatMap((text:string, i:number) => [i > 0 && <br key={i} />, text])
};
export default ContentfulReactRenderOptions;