enum RatesPageFilterEnum{
    // @ts-ignore
    BusinessDeposit = "Home",
    // @ts-ignore
    BusinessLoanAndCredit = "ProductDetailSimple",
    // @ts-ignore
    AutoAndVehicle = "ProductDetailComplex",
    // @ts-ignore
    PersonalLoanAndLOC = "CampaignLanding",
    // @ts-ignore
    CreditCard = "Category",
    // @ts-ignore
    HomeEquityLoanAndLOC = "Landing",
    // @ts-ignore
    MortgageLoan = "Blog",
    // @ts-ignore
    SavingIRAHSA = "Biography",
    // @ts-ignore
    CDs = "RatesDetail",
    // @ts-ignore
    Checking = "Generic"
}

export default RatesPageFilterEnum;